import { Fragment } from 'react';

import { MenuItemWithSearch } from '../../menu/types';
import {
  CountStyled,
  SearchResultStyled,
  SubTitleDelimiterStyled,
  SubTitleLinkStyled,
  SubTitleStyled,
} from '../GlobalSearch.styled';

const SubItem = ({
  text,
  link,
  onSelectItem,
}: {
  text: string;
  link: string;
  onSelectItem: (link: string) => void;
}) => (
  <SearchResultStyled
    key={`${link}-${text}`}
    onClick={() => onSelectItem(link)}
  >
    {text}
  </SearchResultStyled>
);

export const SubItemList = ({
  menuChildren,
  onSelectItem,
  titles = [],
}: {
  menuChildren: MenuItemWithSearch[];
  onSelectItem: (link: string) => void;
  titles?: Record<'text' | 'linkTo', string>[];
}) => (
  <div>
    {menuChildren.map(
      ({ linkTo = '', search, text, children: subChildren, count }) =>
        subChildren ? (
          <SubItemList
            menuChildren={subChildren}
            onSelectItem={onSelectItem}
            titles={[...titles, { text, linkTo }]}
          />
        ) : (
          <div key={text}>
            <SubTitleStyled>
              {[...titles, { text, linkTo }].map((item, index, array) => (
                <Fragment key={item.linkTo + item.text}>
                  <SubTitleLinkStyled onClick={() => onSelectItem(item.linkTo)}>
                    {item.text}
                  </SubTitleLinkStyled>

                  {index < array.length - 1 && (
                    <SubTitleDelimiterStyled> → </SubTitleDelimiterStyled>
                  )}
                </Fragment>
              ))}

              {count ? <CountStyled>{count}</CountStyled> : null}
            </SubTitleStyled>

            {search?.map(({ text: subText, link }) => (
              <SubItem
                key={link}
                text={subText}
                link={link}
                onSelectItem={onSelectItem}
              />
            ))}
          </div>
        ),
    )}
  </div>
);
