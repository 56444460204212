import { styled } from '@mui/material/styles';

import { StyledProps } from '../../shared/helpers/types';

export const SearchContainerStyled = styled('div')(() => ({
  position: 'relative',
}));

export const ResultPopupStyled = styled('div')(({ theme }: StyledProps) => ({
  maxHeight: '464px',
  overflowY: 'scroll',
  backgroundColor: theme?.color?.bg?.primary,
  position: 'absolute',
  left: '1px',
  top: '30px',
  zIndex: 2,
  minWidth: `calc(100% - 2px)`,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  boxShadow:
    '0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
}));

export const ItemStyled = styled('div')(({ theme }: StyledProps) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  color: theme?.color?.typo?.action,
  fontSize: '13px',
  width: '100%',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  borderTop: '1px solid #EBEBEB',
}));

export const TitleStyled = styled('div')(({ theme }: StyledProps) => ({
  overflow: 'hidden',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 12px 8px',
  justifyContent: 'space-between',
  fontWeight: '500',
  fontSize: '17px',
  position: 'relative',
  color: theme?.color?.typo?.primary,
  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));

export const SubTitleStyled = styled('div')(({ theme }: StyledProps) => ({
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 38px 8px 48px',
  fontSize: '16px',
  position: 'relative',
  color: theme?.color?.typo?.primary,

  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));

export const SubTitleLinkStyled = styled('span')(({ theme }: StyledProps) => ({
  color: theme?.color?.typo?.primary,
  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));

export const SubTitleDelimiterStyled = styled('span')(() => ({
  margin: '0 8px',
}));

export const IconWithTextStyled = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
}));

export const CountStyled = styled('div')(({ theme }: StyledProps) => ({
  color: theme?.color.typo.action,
  borderRadius: '20px',
  border: `2px solid ${theme?.color.typo.action}`,
  fontSize: '12px',
  lineHeight: '16px',
  padding: '0 10px',
  position: 'absolute',
  right: '24px',
  bottom: '8px',
}));

export const LoaderStyled = styled('div')(() => ({
  padding: '8px 12px',
}));

export const SearchResultStyled = styled('div')(({ theme }: StyledProps) => ({
  fontWeight: '400',
  fontSize: '13px',
  padding: '8px 38px 8px 48px',
  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));
