/* eslint-disable no-case-declarations */
import axios, { CancelTokenSource } from 'axios';
import { mapValues, set, get } from 'lodash';
import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useMenuConfig } from '../../../config/useMenuConfig';
import { useLogHandleTime } from '../../../hooks/useLogHandleTime';
import { useAppDispatch } from '../../../store/hooks';
import { MenuItemWithSearch } from '../../menu/types';
import { useCprmService } from '../../services/Cprm.service';
import { useCrmService } from '../../services/Crm.service';
import { useNodeBackService } from '../../services/NodeBack.service';
import { useShaperService } from '../../services/Shaper.service';
import { CprmSearchResponse } from '../../services/Types/cprm.types';
import { CrmSearchResponse } from '../../services/Types/crm.types';
import { NodebackSearchResponse } from '../../services/Types/nodeback.types';
import { ShaperSearchResponse } from '../../services/Types/shaper.types';
import {
  deleteNodesWithoutSearch,
  generateLinkPathMap,
  getCountsFromNodeBack,
  parseCprmSearch,
  parseCrmSearch,
  parseNodeBackSearch,
  parseShaperSearch,
  renameDefaultLinkToLinkTo,
  setCountForParentNodes,
} from '../helpers';
import { setCrmBadgesState } from '../reducer';

let cancelToken: CancelTokenSource;
// workaround to stop the previous code execution
let currentRequestId = 0;

/*
  We already have menuConfig; the search results are structurally similar to menuConfig in a different layout.
  We copy menuConfig variable and update it with the search results, then removing nodes that don't contain `search` results
 */
export function useOnSearch(
  setSearchData: Dispatch<SetStateAction<MenuItemWithSearch[]>>,
) {
  const dispatch = useAppDispatch();
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();

  const [isLoading, setIsLoading] = useState(false);

  // search result is the menu in a different css
  const newSearchData = useRef<MenuItemWithSearch[]>(
    structuredClone(menuConfig),
  );

  const linkPathMap = useMemo(
    () => generateLinkPathMap(menuConfig, ''),
    [menuConfig],
  );

  const { requestCrmSearch } = useCrmService();
  const { requestShaperSearch } = useShaperService();
  const { requestNodeBackSearch } = useNodeBackService();
  const { requestCprmSearch } = useCprmService();

  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('run-global-search');

  const onSearch = useCallback(
    async (value: string) => {
      currentRequestId += 1;
      const requestId = currentRequestId;

      if (cancelToken) {
        cancelToken.cancel();
      }

      cancelToken = axios.CancelToken.source();

      const searchValue = value.trim();

      try {
        setIsLoading(true);

        // key is path to menu element
        // value is query param name
        let contextSearchUriByPosition: Record<string, string> = {};

        const services = [
          {
            name: 'crm',
            request: () => requestCrmSearch(searchValue, cancelToken),
          },
          {
            name: 'cprm',
            request: () => requestCprmSearch(searchValue, cancelToken),
          },
          {
            name: 'notifications',
            request: () => requestShaperSearch(searchValue, cancelToken),
          },
          {
            name: 'backoffice',
            request: () => requestNodeBackSearch(searchValue, cancelToken),
          },
        ];

        setStartHandleTime();

        const responses = await Promise.allSettled(
          services.map((service) => service.request()),
        );

        if (requestId !== currentRequestId) {
          return;
        }

        logHandleTime();

        const dataForInsert: { path: string; text: string; link: string }[][] =
          [];

        let menuCounts = {};
        let searchCounts = {};

        responses.forEach((response, index) => {
          if (response.status === 'rejected') {
            return;
          }

          switch (services[index].name) {
            case 'crm':
              const searchResultsFromCRM = parseCrmSearch(
                response.value.data as CrmSearchResponse,
                linkPathMap,
              );

              dataForInsert.push(searchResultsFromCRM.searchResult);

              menuCounts = {
                ...menuCounts,
                ...searchResultsFromCRM.crmMenuCount,
              };

              searchCounts = {
                ...searchCounts,
                ...searchResultsFromCRM.countByPath,
              };

              contextSearchUriByPosition = {
                ...contextSearchUriByPosition,
                ...searchResultsFromCRM.contextSearchPaths,
              };

              break;

            case 'cprm':
              const searchResultsFromCprm = parseCprmSearch(
                response.value.data as CprmSearchResponse,
                linkPathMap,
              );

              dataForInsert.push(searchResultsFromCprm.searchResult);

              menuCounts = {
                ...menuCounts,
                ...(response.value.data as CprmSearchResponse).tabs,
              };

              searchCounts = {
                ...searchCounts,
                ...searchResultsFromCprm.countByPath,
              };

              break;

            case 'notifications':
              const searchResultsFromShaper = parseShaperSearch(
                response.value.data as ShaperSearchResponse,
                linkPathMap,
              );

              dataForInsert.push(searchResultsFromShaper.searchResult);

              menuCounts = {
                ...menuCounts,
                ...(response.value.data as ShaperSearchResponse).tabs,
              };

              searchCounts = {
                ...searchCounts,
                ...searchResultsFromShaper.countByPath,
              };

              break;

            case 'backoffice':
              const searchResultsFromNodeBack = parseNodeBackSearch(
                response.value.data as NodebackSearchResponse,
                linkPathMap,
              );

              dataForInsert.push(searchResultsFromNodeBack.searchResult);

              menuCounts = {
                ...menuCounts,
                ...getCountsFromNodeBack(
                  (response.value.data as NodebackSearchResponse).count,
                ),
              };

              searchCounts = {
                ...searchCounts,
                ...searchResultsFromNodeBack.countByPath,
              };

              contextSearchUriByPosition = {
                ...contextSearchUriByPosition,
                ...mapValues(
                  searchResultsFromNodeBack.countByPath,
                  () => 'contextSearch',
                ),
              };

              break;

            default:
          }
        });

        // transforms dataForInsert to array [{ path: { text, link } }]
        Object.entries(
          dataForInsert.flat().reduce((acc, { path, text, link }) => {
            if (!acc[path]) {
              acc[path] = [];
            }

            acc[path].push({ text, link });
            return acc;
          }, {} as Record<string, Array<{ link: string; text: string }>>),
        ).forEach(([path, rowsForInsert]) => {
          set(newSearchData.current, `${path}.search`, rowsForInsert);
        });

        Object.entries(searchCounts).forEach(([path, count]) => {
          set(newSearchData.current, `${path}.count`, count);
        });

        // update links in search result for context search
        Object.entries(contextSearchUriByPosition).forEach(
          ([path, queryParamName]) => {
            let newLinkTo = get(newSearchData.current, `${path}.linkTo`, '');

            if (newLinkTo) {
              const url = new URL(newLinkTo, window.location.origin);
              url.searchParams.set(queryParamName, searchValue);

              // keep the relative path and query parameters
              newLinkTo = url.pathname + url.search;
            }

            set(newSearchData.current, `${path}.linkTo`, newLinkTo);
          },
        );

        newSearchData.current = deleteNodesWithoutSearch(
          newSearchData.current,
          searchValue,
        );
        newSearchData.current = renameDefaultLinkToLinkTo(
          newSearchData.current,
        );

        setCountForParentNodes(newSearchData.current);

        dispatch(setCrmBadgesState(menuCounts));

        setSearchData(newSearchData.current);

        // this block is needed for filtering the All Applications and Clients tables
        if (window.CRM_UI?.search) {
          window.CRM_UI.search(searchValue);
        }

        // reset for new search
        newSearchData.current = structuredClone(menuConfig);

        setIsLoading(false);
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          Notification.error({
            title: error?.message,
          });
        }

        setIsLoading(false);
      }
    },
    [
      dispatch,
      linkPathMap,
      logHandleTime,
      menuConfig,
      requestCprmSearch,
      requestCrmSearch,
      requestNodeBackSearch,
      requestShaperSearch,
      setSearchData,
      setStartHandleTime,
    ],
  );

  return { isLoading, onSearch, cancelToken };
}
